import '../scss/stream_player.scss';

import { plugin_registry, PluginBase } from "nk-plugin-registry";


@plugin_registry.register("StreamPlayer")
class StreamPlayer extends PluginBase {
    constructor($node) {
        super($node);
        this.$playButton = $node.querySelector('[data-js-select=play-button]');
        this.$players = $node.querySelectorAll("audio[class='stream']");
        //this.$originalSourceUrl = this.$players[0].getAttribute("src");
    }

    loaded($node) {
        super.loaded($node);

        this._install_event_listeners();
    }

    _install_event_listeners = () => {
        this.$playButton.addEventListener("click", this._play_button_click);
    }

    _play_button_click = (e) => {
        const playerID = e.srcElement.parentElement.id;
        const classList = e.srcElement.classList;
        let activePlayer;
        for (const player of this.$players) {
            if (player.id === `player-${playerID}`) {
               activePlayer = player;
            }
        }
        const streamSource = activePlayer.getAttribute("data-src")
        if (streamSource) {
            if (classList.contains("icon-play-circle")) {

                activePlayer.setAttribute("src", streamSource);
                activePlayer.play();
                classList.replace("icon-play-circle", "icon-pause-circle");
                classList.add("orange");
            } else {
                activePlayer.pause()
                activePlayer.setAttribute("src", "");
                classList.replace("icon-pause-circle", "icon-play-circle");
                classList.remove("orange");
            }
        }
    }
}